<template>
  <div>
    <div v-if="id === null">
      <h2>Join Game</h2>
      <form class="w-9 mx-auto" @submit.prevent="join">
        <input
          v-model="form.code"
          class="w-full"
          placeholder="code"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          type="text"
          @focus="form.code = ''"
        />
        <input
          v-model="form.name"
          class="w-full"
          placeholder="name"
          type="text"
        />
        <button type="submit" class="btn w-full">join</button>
      </form>
    </div>

    <div v-else-if="winner">
      <h2>Game Over</h2>
      <div class="size-5">{{ winner }} won!</div>
    </div>

    <template v-else-if="!round">
      <h2>waiting...</h2>
    </template>

    <template v-else-if="!hand || turn === -1">
      <h2 class="mb-2">Round {{ round }}</h2>
      <div v-if="dealer !== id" class="size-5">
        waiting for {{ players[dealer].name }} to deal
      </div>
      <button v-else class="btn" @click="game.send('deal')">deal</button>
    </template>

    <div v-else class="relative">
      <hand
        ref="hand"
        :hand="hand"
        :type="type"
        @selectedCard="selected = $event"
        @selectedSet="$set(sets, sets.length - 1, $event)"
      />

      <div class="absolute top-full w-full">
        <template v-if="turn === id">
          <template v-if="hand.length === round">
            <div>draw from</div>
            <button class="btn" @click="game.send('draw', false)">deck</button>
            <button class="btn" @click="game.send('draw', true)">trash</button>
          </template>

          <template v-else-if="!isDiscarding && !isGoingOut">
            <div v-if="out !== -1">{{ players[out].name }} went out</div>
            <div v-else>finish your turn</div>

            <button class="btn" @click="goOut">go out</button>
            <button v-if="out === -1" class="btn" @click="discard">
              discard
            </button>
          </template>

          <template v-else-if="isDiscarding">
            <div>select a card</div>
            <button class="btn" @click="cancel">cancel</button>
            <button
              :disabled="selected === null"
              class="btn"
              @click="confirmDiscard"
            >
              discard
            </button>
          </template>

          <template v-else-if="isGoingOut">
            <div>select set {{ sets.length }}</div>
            <button class="btn" @click="cancel">cancel</button>
            <button :disabled="!canNextSet" class="btn" @click="selectNextSet">
              next set
            </button>
            <button
              :disabled="numSelected !== round"
              class="btn"
              @click="confirmGoOut"
            >
              confirm
            </button>
          </template>
        </template>
        <template v-else>{{ players[turn].name }}'s turn</template>
      </div>
    </div>
  </div>
</template>

<script>
import game from '@/game'
import Hand from '@/components/Hand'

export default {
  components: {
    Hand
  },

  data: () => ({
    game,
    id: null,
    form: {
      code: '',
      name: ''
    },
    dealer: null,
    hand: null,
    isDiscarding: false,
    isGoingOut: false,
    out: null,
    players: [],
    round: null,
    selected: null,
    sets: [],
    trash: {},
    turn: null,
    type: null,
    winner: null
  }),

  computed: {
    canNextSet() {
      const { round, numSelected, sets, type } = this
      const set = sets[sets.length - 1]
      const min = type === 'quiddler' ? 2 : 3
      return set.length >= min && numSelected < round
    },

    numSelected() {
      return this.sets.reduce((a, s) => a + s.length, 0)
    }
  },

  async mounted() {
    try {
      const saved = localStorage.getItem('cards')
      if (saved) this.form = JSON.parse(saved)
    } catch (e) {
      console.warn('saved config invalid')
    }

    const copy = data => Object.assign(this, data)
    const commands = ['join', 'players', 'sync']
    commands.forEach(command => game.on(command, copy))

    game.on('error', ({ message }) => {
      this.$toasted.show(message)
      if (message === 'invalid code' && this.id !== null) {
        location.reload()
      }
    })

    game.on('draw', card => {
      this.hand.push(card)
    })

    game.on('discard', id => {
      if (this.turn === -1) return
      const index = this.hand.findIndex(c => c.id === id)
      this.hand.splice(index, 1)
      this.cancel()
    })

    game.on('hand', ({ hand }) => {
      this.hand = hand.sort((a, b) => b.value - a.value)
    })

    game.on('game over', () => {
      const dir = this.type === 'quiddler' ? -1 : 1
      this.winner = this.players
        .slice()
        .sort((a, b) => dir * (b.score - a.score))
        .pop().name
    })

    await game.connect()
  },

  beforeDestroy() {
    game.all.clear()
    delete game.ws.onclose
  },

  methods: {
    cancel() {
      this.isDiscarding = false
      this.isGoingOut = false
      this.$refs.hand.deselect()
    },

    confirmDiscard() {
      const id = this.selected
      game.send('discard', { id })
      this.isDiscarding = false
    },

    confirmGoOut() {
      const out = this.sets
      const ids = [].concat(...this.sets)
      const discard = this.hand.find(c => !ids.includes(c.id))
      game.send('discard', { id: discard.id, out })
    },

    discard() {
      this.isDiscarding = true
      this.$refs.hand.selectDiscard()
    },

    goOut() {
      this.$refs.hand.deselect()
      this.isGoingOut = true
      this.sets = []
      this.selectNextSet()
    },

    join() {
      localStorage.setItem('cards', JSON.stringify(this.form))
      this.game.send('join', this.form)
    },

    selectNextSet() {
      this.sets.push([])
      this.$refs.hand.selectSet(this.sets.length - 1)
    }
  }
}
</script>
