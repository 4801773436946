<template>
  <transition-group tag="div" class="hand">
    <card
      v-for="(card, i) of hand"
      v-bind="card"
      :key="card.id"
      :selected="selected === i || selectedCards[card.id] !== undefined"
      :style="card.style || cardStyle(i)"
      :type="type"
      @click="onClick(i)"
    />
  </transition-group>
</template>

<script>
import Card from '@/components/Card'

export default {
  components: {
    Card
  },

  props: {
    hand: Array,
    type: String
  },

  data: () => ({
    currentSet: null,
    selected: null,
    selectedCards: {},
    selectedSet: [],
    setColors: [
      '#0080ff80',
      '#ff800080',
      '#ff008080',
      '#00ff8080',
      '#ffff0080'
    ],
    isDiscarding: false,
    isGoingOut: false
  }),

  watch: {
    selected(index) {
      const id = index === null ? null : this.hand[index].id
      this.$emit('selectedCard', id)
    },

    selectedSet: {
      handler(set) {
        console.log('wtf', set)
        // const ids = set.map(index => this.hand[index].id)
        this.$emit('selectedSet', set)
      }
      // deep: true
    }
  },

  methods: {
    cardStyle(i) {
      const id = this.hand[i].id
      const a = i - 0.5 * (this.hand.length - 1)
      const style = { '--rotate': `${5 * a}deg` }

      if (this.selected === i || this.selectedCards[id] !== undefined) {
        let color = this.isDiscarding ? '#ff000080' : '#8000ff80'
        if (this.isGoingOut) {
          const colorIndex = this.selectedCards[id]
          color = this.setColors[colorIndex]
        }

        style.boxShadow = `0 0 0 3px ${color}`
      }

      return style
    },

    deselect() {
      this.selected = null
      this.selectedCards = {}
      this.isDiscarding = false
      this.isGoingOut = false
    },

    async onClick(to) {
      const id = this.hand[to].id

      if (this.isGoingOut) {
        if (this.selectedCards[id] !== undefined) {
          console.log(this.selectedCards[id])
          if (this.selectedCards[id] === this.currentSet) {
            this.$delete(this.selectedCards, id)
            const index = this.selectedSet.indexOf(id)
            this.selectedSet.splice(index, 1)
          }
        } else {
          this.selectedCards[id] = this.currentSet
          this.selectedSet.push(id)
          const [card] = this.hand.splice(to, 1)

          await this.$nextTick()
          const num = Object.keys(this.selectedCards).length
          this.hand.splice(num - 1, 0, card)
        }
        return
      }

      const from = this.selected

      if (from === to) {
        this.selected = null
        return
      }

      if (from === null || this.isDiscarding) {
        this.selected = to
        return
      }

      if (to === from - 1) to--
      if (from > to) to++

      this.hand[from].style = this.cardStyle(from)
      const [card] = this.hand.splice(from, 1)

      await this.$nextTick()
      this.selected = to
      this.hand.splice(to, 0, card)

      await this.$nextTick()
      this.selected = null
      delete card.style
    },

    selectDiscard() {
      this.isDiscarding = true
    },

    selectSet(num) {
      this.isGoingOut = true
      this.currentSet = num
      this.selectedSet = []
    }
  }
}
</script>

<style lang="scss" scoped>
.hand {
  position: relative;
  margin-top: 5em;
  height: 10em;
  font-size: 2.3vw;

  .card {
    position: absolute;

    transform-origin: 50% 200%;
    transform: rotate(var(--rotate, 0)) translateY(var(--translate-y, 0));
    // transition: transform 300ms ease;

    // &.v-leave-active {
    //   transition-duration: 0s;
    // }

    &.v-enter:not(.selected) {
      opacity: 0;
      --translate-y: 10em;
    }

    &.selected {
      --translate-y: -2em;
    }

    &.v-leave-to {
      opacity: 0;
      --translate-y: -10em;
    }
  }
}
</style>
